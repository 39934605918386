import React from 'react'
import SEO from '../Component/Services/SEO'
import Header from '../Component/header'
import Footer from '../Component/Foot';

const SEOPage = () => {
  return (
   <>
    <Header />
   <SEO />
  
   <Footer />
   </>
  )
}

export default SEOPage
