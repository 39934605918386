import React from 'react'
import Header from '../Component/header'
import Footer from '../Component/Foot';
import Contact from '../Component/Contact'

const ContactPage = () => {
  return (
    <div>
        <Header/>
        <Contact/>
        <Footer/>
    </div>
  )
}

export default ContactPage
