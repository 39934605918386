import React from 'react'
import GoogleMyBusiness from '../Component/Services/Google'
import Header from '../Component/header'
import Footer from '../Component/Foot';

const GoogleMy = () => {
  return (
    <div>
        <Header />
        <GoogleMyBusiness/>
        <Footer />
      
    </div>
  )
}

export default GoogleMy
