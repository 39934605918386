import React from 'react'
import PerformanceMarketing from '../Component/Services/PerformanceMarketing'
import Header from '../Component/header'
import Footer from '../Component/Foot'

const Performance_Marketing = () => {
  return (
   <>
    <Header />
  
  <PerformanceMarketing/>
   <Footer />
   </>
  )
}

export default Performance_Marketing;
