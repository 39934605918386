import React from 'react'
import Header from '../Component/header'
import Footer from '../Component/Foot';
import About from '../Component/About'

const AboutPage = () => {
  return (
    <div>
        <Header/>
        <About/>
        <Footer/>
      
    </div>
  )
}

export default AboutPage
