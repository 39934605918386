import React from 'react'
import Header from '../Component/header';
import Footer from '../Component/Foot';
import WebDev from '../Component/Services/WebDev';
const WebDevPage = () => {
  return (
   <>
   <Header/>
   <WebDev/>
   <Footer/>
   </>
  )
}

export default WebDevPage
