import React from 'react'
import SocialMedia from '../Component/Services/SocialMedia'
import Header from '../Component/header'
import Footer from '../Component/Foot';

const Social = () => {
  return (
   <>
    <Header />
   <SocialMedia/>
  
   <Footer />
   </>
  )
}

export default Social
